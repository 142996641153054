<!-- ----------------------------------------- template ----------------------------------------- -->
<template>
  <el-dialog
    :visible.sync="showRoomDialog"
    :title="roomInfo.id == '' ? '添加房间' : '编辑房间'"
  >
    <div>
      <el-form :model="roomInfo" :rules="rules" ref="roomForm">
        <el-form-item label="房间名称" prop="name">
          <el-input v-model="roomInfo.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="房间位置" prop="location">
          <el-input v-model="roomInfo.location" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="房间大小" prop="room_size">
          <el-input
            v-model="roomInfo.room_size"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="房间排列">
          <el-select v-model="roomInfo.arrangement" placeholder="请选择">
            <el-option label="剧院式" value="剧院式"></el-option>
            <el-option label="课桌式" value="课桌式"></el-option>
            <el-option label="圆桌式" value="圆桌式"></el-option>
            <el-option label="吧台式" value="吧台式"></el-option>
          </el-select>
          <el-input
            v-model="custom_arrangement"
            placeholder="自定义房间排列"
            style="width: 300px; margin-left: 15px"
          ></el-input>
          (自定义)
        </el-form-item>
      </el-form>
      <div style="text-align: right">
        <span slot="footer" class="dialog-footer">
          <el-button @click="showRoomDialog = false">取 消</el-button>
          <el-button type="primary" @click="saveRoom()">确 定</el-button>
        </span>
      </div>
    </div>
  </el-dialog>
</template>

<!-- ------------------------------------------ script ------------------------------------------ -->
<script>
import {
    getMeetingRooms,
    removeMeetingRoom,
    updateMeetingRoom,
} from '../../api/api'
export default {
  name: "",
  components: {},

  props: {
    meetingId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      showRoomDialog: false,
      custom_arrangement: "",
      roomInfo: {},

      rules: {
        name: [{ required: true, message: "Please input", trigger: "blur" }],
        location: [
          { required: true, message: "Please input", trigger: "blur" },
        ],
        room_size: [
          { required: true, message: "Please input", trigger: "blur" },
        ],
      },
    };
  },

  /* 一.生命周期函数 */
  created() {},

  /* 二.监控函数 */
  watch: {},

  computed: {},

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 事件调用函数注释
    actionXxx() {},

    /* ----------------------内部功能函数------------------------ */
    // 内部功能函数注释
    show(row) {
      this.custom_arrangement = "";
      if (row) {
        this.roomInfo = {
          id: row._id.$id,
          meeting_id: row.meetingId,
          name: row.name,
          location: row.location,
          room_size: row.room_size,
          arrangement: row.arrangement,
        };
      } else {
        this.roomInfo = {
          id: "",
          meeting_id: this.meetingId,
          name: "",
          location: "",
          room_size: "",
          arrangement: "",
        };
      }
      this.showRoomDialog = true;
    },

    saveRoom() {
      this.$refs.roomForm.validate((val) => {
        if (val) {
          if (!this.roomInfo.arrangement) {
            this.roomInfo.arrangement = this.custom_arrangement;
          }
          updateMeetingRoom(this.roomInfo).then((res) => {
            this.$message.success("保存成功");
            this.$emit("on-complete", res.data.data._id.$id);
            this.showRoomDialog = false;
          });
        }
      });
    },

    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    ajaxXxx() {},
  },
};
</script>

<!-- ------------------------------------------ style ------------------------------------------ -->
<style scoped lang="scss"></style>
