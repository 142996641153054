<!-- ----------------------------------------- template ----------------------------------------- -->
<template>
  <div class="import-chart">
    <!-- :before-close="handleClose" -->
    <el-dialog :title="title" :visible.sync="isShowDialog" width="960px">
      <!-- 信息提取阶段 -->
      <el-form
        v-loading="isLoading"
        ref="form"
        :rules="rules"
        :model="formData"
        label-width="80px"
        v-if="step === 1"
      >
        <el-form-item label="关键词" prop="keywords">
          <el-input
            type="textarea"
            placeholder="关键词用逗号连接，如“日程名称,日程类型,是否开放”"
            v-model="formData.keywords"
          ></el-input>
        </el-form-item>
        <el-form-item label="会议概要" prop="content">
          <el-input
            type="textarea"
            placeholder="请填写会议概要，如“明天下午两点半到四点，邀请胡老师在会议室参与PHP专场的宣讲会”"
            v-model="formData.content"
          ></el-input>
        </el-form-item>
      </el-form>

      <!-- 数据确认阶段 -->
      <div v-if="step === 2">
        <el-table :data="tableConf.dataList" border style="width: 100%">
          <el-table-column
            :prop="column.key"
            :label="column.label"
            :width="column.width"
            v-for="(column, columnIndex) in tableConf.columns"
            :key="columnIndex"
            align="center"
          >
            <template slot-scope="scope">
              <DwSelect v-if="column._tag === 'select'" :config="column._conf" v-model="scope.row[column.key]"></DwSelect>
              
              <div
                v-else
                @click="actionEditItem(scope)"
                class="import-chart-tableitem"
              >
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 1 }"
                  :id="`${column.label}-${scope.$index}`"
                  v-if="curLabel === column.label && curIndex === scope.$index"
                  v-model="scope.row[column.key]"
                  class="import-chart-input"
                  @blur="actionItemBlur(scope)"
                ></el-input>
                <div v-else class="import-chart-cell">
                  {{ scope.row[column.key] }}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80" fixed="right">
            <template slot-scope="scope">
              <el-button
                type="danger"
                size="small"
                @click="actionDeleteItem(scope)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="import-chart-addItem" @click="actionAddItem">新增一条</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowDialog = false">取 消</el-button>
        <el-button
          type="primary"
          @click="ajaxExtract"
          v-if="step === 1"
          v-loading="isLoading"
          >解 析</el-button
        >
        <el-button type="primary" @click="actionChangeStep(1)" v-if="step === 2"
          >上一步</el-button
        >
        <el-button type="primary" @click="actionConfrim" v-if="step === 2"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<!-- ------------------------------------------ script ------------------------------------------ -->
<script>
import { extractInformationBasedAI } from "../../api/api";
import DwSelect from './components/dw-select.vue'
export default {
  name: "",
  components: {
    DwSelect
  },

  props: {
    title: {
      type: String,
      default: "批量导入",
    },
  },

  data() {
    return {
      curLabel: null,
      curIndex: null,
      isShowDialog: false,
      isLoading: false,
      step: 1, // 1: 信息提取阶段 2: 数据确认阶段
      rules: {
        keywords: [
          { required: true, message: "请输入关键词", trigger: "blur" },
        ],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
      },
      formData: {
        keywords: "",
        content: "",
      },
      tableConf: {
        dataList: [],
        columns: [{}],
        // dataList: [
        //   { a: "日程1", b: "类型1", c: "PHP专场1" },
        //   { a: "日程2", b: "类型2" },
        // ],
      },
      extractInfo: {}, // 提取的信息
    };
  },

  /* 一.生命周期函数 */
  created() {
  },

  /* 二.监控函数 */
  watch: {},

  computed: {},

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 事件调用函数注释
    actionChangeStep(step) {
      switch (step) {
        case 1:
          // this.formData.keywords = ''
          // this.formData.content = ''
          this.step = 1;
          break;
      }
    },
    actionItemBlur(data) {
      if (
        this.curIndex === data.$index &&
        this.curLabel === data.column.label
      ) {
        this.curLabel = null;
        this.curIndex = null;
      }
    },
    actionEditItem(data) {
      this.curLabel = data.column.label;
      this.curIndex = data.$index;
      this.$nextTick(() => {
        const input = document.getElementById(
          `${data.column.label}-${data.$index}`
        );
        input.focus();
      });
      console.log(data);
    },
    actionAddItem() {
      this.tableConf.dataList.push({});
    },
    actionDeleteItem(data) {
      this.tableConf.dataList.splice(data.$index, 1);
    },
    actionConfrim() {
      this.$emit('on-confirm', this.tableConf.dataList)
    },

    /* ----------------------内部功能函数------------------------ */
    // 内部功能函数注释
    show(options = {}) {
      this.step = 1
      this.tableConf.dataList = []
      this.tableConf.columns = options.columns || []
      this.isShowDialog = true;
    },
    hide () {
      this.isShowDialog = false;
    },
    formatTableData () {
      const { dataList, columns } = this.tableConf
      columns.forEach(item => {
        dataList.forEach((row, index) => {
          const { _tag = '', _conf, key } = item
          switch (item._tag) {
            case 'select':
              const item = _conf.options.find(option => [option.label, option.value].includes(row[key]))
              row[key] = item? item.value : ''
              break
            case 'default':
              break
          }
        })
      })
      this.tableConf.dataList = JSON.parse(JSON.stringify(dataList))
      if (this.tableConf.dataList.length === 0) {
        this.actionAddItem()
      }
    },

    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    ajaxExtract() {
      if (this.isLoading) return;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.isLoading = true;
          extractInformationBasedAI(this.formData)
            .then((res) => {
              if (res.data.code === 0) {
              console.log(123, res.data.data)
              try {
                  this.tableConf.dataList.push(res.data.data)
                  console.log('tableConf', this.tableConf)
                  this.formatTableData()
                } catch (err) {
                  this.extractInfo = {};
                }
                this.step = 2;
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
  },
};
</script>

<!-- ------------------------------------------ style ------------------------------------------ -->
<style scoped lang="scss">
.import-chart {
  &-addItem {
    margin-top: 10px;
    border: 1px dashed #c3c7cf;
    padding: 10px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    border-radius: 5px;
    background: #fff;
    &:hover {
      background: #f5f7fa;
    }
  }
  :deep(.el-table tbody .el-table__cell) {
    padding: 0;
  }
  &-input {
    :deep(.el-textarea__inner) {
      border: none;
      background: transparent;
      padding: 0;
      resize: none;
    }
  }
  &-cell {
    min-height: 40px;
    line-height: 24px;
    padding: 8px 0;
  }
}
</style>
