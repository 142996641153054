<!-- ----------------------------------------- template ----------------------------------------- -->
<template>
  <el-dialog
    :visible.sync="showAddPartnerDialog"
    :title="partyInfo.id == '' ? '添加客户' : '编辑客户'"
    width="55%"
  >
    <div>
      <div>
        <el-form :model="partyInfo" :rules="rules" ref="partyInfo">
          <el-form-item label="名称" prop="name">
            <el-input v-model="partyInfo.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="单位名称" prop="company">
            <el-input
              v-model="partyInfo.company"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="登录账号" prop="master_email">
            <el-input
              v-model="partyInfo.master_email"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="Logo">
            <el-upload
              class="avatar-uploader"
              action="/"
              :show-file-list="false"
              :before-upload="(file) => beforeLogoUpload(file)"
            >
              <img
                v-if="partyInfo.logo_url"
                :src="partyInfo.logo_url"
                style="object-fit: cover"
                class="el-icon-plus avatar-uploader-icon"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="">
            <label>介绍</label>
            <quill-editor
              class="ql-container"
              style="margin-bottom: 20px"
              ref="myTextEditor"
              v-model="partyInfo.intro"
              :options="editorOption"
            >
            </quill-editor>
          </el-form-item>
        </el-form>
      </div>
      <div style="text-align: right; margin-top: 100px">
        <span slot="footer" class="dialog-footer">
          <el-button @click="showAddPartnerDialog = false">取 消</el-button>
          <el-button type="primary" @click="confirmAddPartner()"
            >确 定</el-button
          >
        </span>
      </div>
    </div>
  </el-dialog>
</template>

<!-- ------------------------------------------ script ------------------------------------------ -->
<script>
import {
  getQiniuToken,
  updatePartner,
  addMettingPartner,
  removeMeetingPartner,
  getMeetingPartners,
  postRequest,
} from "../../../api/api";
import "../../../assets/common/common.css";
import { getUserId } from "../../../utils/store";
import * as qiniu from "qiniu-js";
export default {
  name: "",
  components: {},
  props: {
    meetingId: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      showAddPartnerDialog: false,
      partyInfo: {},
      rules: {
        master_email: [
          { required: true, message: "Please input", trigger: "blur" },
        ],
        name: [{ required: true, message: "Please input", trigger: "blur" }],
        company: [{ required: true, message: "Please input", trigger: "blur" }],
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
    };
  },

  /* 一.生命周期函数 */
  created() {},

  /* 二.监控函数 */
  watch: {},

  computed: {},

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 事件调用函数注释
    actionXxx() {},
    async beforeLogoUpload(file) {
      const result = await getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: (res) => {
            this.partyInfo.logo_url =
              "http://mainbucket.reachable-edu.com/" + res.key;
          },
        };
        this.observable.subscribe(observer);
      }
    },

    /* ----------------------内部功能函数------------------------ */
    // 内部功能函数注释
    show(row) {
      if (row) {
        this.partyInfo = {
          id: row._id.$id,
          name: row.name,
          logo_url: row.logo_url,
          company: row.company,
          intro: row.intro,
          master_email: row.master_email,
          organizer_id: row.organizer_id,
        };
      } else {
        this.partyInfo = {
          id: "",
          name: "",
          logo_url: "",
          company: "",
          intro: "",
          master_email: "",
          organizer_id: getUserId(),
        };
      }
      this.showAddPartnerDialog = true;
    },
    confirmAddPartner() {
      this.$refs.partyInfo.validate((val) => {
        if (val) {
          updatePartner(this.partyInfo).then((res) => {
            let partner_id = res.data.data._id.$id;
            if (this.partyInfo.id == "") {
              this.doAddMettingPartner(partner_id);
            } else {
              this.showAddPartnerDialog = false;
              this.$emit("on-complete", partner_id);
            }
          });
        }
      });
    },
    doAddMettingPartner(partner_id) {
      addMettingPartner(this.meetingId, partner_id, getUserId()).then((res) => {
        let result = res.data;
        if (result.code != 0) {
          this.$message.warning(result.msg);
          return;
        }
        this.$message.success("添加成功");
        this.$emit("on-complete", partner_id);
        this.showAddPartnerDialog = false;
      });
    },

    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    ajaxXxx() {},
  },
};
</script>

<!-- ------------------------------------------ style ------------------------------------------ -->
<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 72px;
  height: 72px;
  line-height: 72px;
  text-align: center;
}

.avatar {
  width: 72px;
  height: 72px;
  display: block;
}
</style>
