<!-- ----------------------------------------- template ----------------------------------------- -->
<template>
  <div>
  <el-select :value="modelValue" @change="actionChange">
    <el-option
      v-for="(item, index) in config.options"
      :key="index"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</div>
</template>

<!-- ------------------------------------------ script ------------------------------------------ -->
<script>
export default {
  name: '',
  components: {
  },
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  props: {
    config: {
      type: Object,
      default: () => {
        return {
          options: []
        }
      }

    },
    modelValue: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
    }
  },

  /* 一.生命周期函数 */
  created () {
  },

  /* 二.监控函数 */
  watch: {
  },

  computed: {
  },

  /* 三.内部功能函数 */
  methods: {
    /* ----------------------事件调用函数------------------------ */
    // 事件调用函数注释
    actionChange (data) {
      this.$emit('update:modelValue', data)
    },

    /* ----------------------内部功能函数------------------------ */
    // 内部功能函数注释
    doSomething () {

    },

    /* ----------------------服务请求函数------------------------ */
    // 服务请求函数注释
    ajaxXxx () {

    }
  }
}
</script>

<!-- ------------------------------------------ style ------------------------------------------ -->
<style scoped lang="scss">

</style>
